import axios from 'axios';
import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useState(
        "18|MbeegzGzSuu7VQUFuR1F3a88aMKWNTSgJE4C7vKz0b1884bc"
    );
    const [originUrl, setOriginUrl] = useState('https://demo.thestablegame.com');
    const [user, setUser] = useState(null);
    const [realToken, setRealToken] = useState('');
    const [eventId, setEventId] = useState('');
    const [isAuthReady, setIsAuthReady] = useState(false);
    const [userData, setUserData] = useState(null);
    const [optionalFields, setOptionalFields] = useState(null);
    const [localTourUUID, setLocalTourUUID] = useState(null);

    const logout = () => {
        setToken('');
        setUser(null);
    };

    useEffect(() => {
        // Simulando carga de token desde localStorage u otro lado
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            setToken(storedToken);
        }
        // Indicar que la autenticación terminó de inicializar
        setIsAuthReady(true);
    }, []);

    // Actualizar realToken cuando token cambie
    useEffect(() => {
        if (token) {
            setRealToken(`Bearer ${token}`);
        } else {
            setRealToken(null);
        }
    }, [token]);


    // Crear la instancia de axios cuando realToken y originUrl estén disponibles
    const axiosInstance = useMemo(() => {
        if (!realToken) return null;
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
                ? `${process.env.REACT_APP_API_BASE_URL}/api`
                : 'https://back.thestablegame.com/api',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-Origin-Domain': originUrl,
                'Authorization': realToken,
            },
        });
    }, [realToken, originUrl]);
    // Definir fetchUserData como función memoizada para poder usarla en otros lugares si se necesita
    const fetchUserData = useCallback(async () => {
        try {
            if (!axiosInstance) return;
            const response = await axiosInstance.get('/userDataAPI/get-user-data');
            // console.log("RESPUESTA AQUÍ", response.data);
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [axiosInstance]);

    // Definir fetchOptionalFields con useCallback y exponerla en el contexto
    const fetchOptionalFields = useCallback(async () => {
        try {
            if (!axiosInstance) return;
            const response = await axiosInstance.get("/activatableAPI/getUserActivatables");
            // console.log("response", response.data);
            setOptionalFields(response.data.activatable);
            // console.log("optionalFields", response.data.activatable);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    }, [axiosInstance]);

    // Llamar a fetchUserData automáticamente cuando axiosInstance esté listo
    useEffect(() => {
        if (axiosInstance) {
            fetchUserData();
            fetchOptionalFields();
        }
    }, [axiosInstance, fetchUserData, fetchOptionalFields]);


    return (
        <AuthContext.Provider value={{
            token,
            setToken,
            logout,
            axiosInstance,
            isAuthReady,
            realToken,
            setOriginUrl,
            setEventId,
            eventId,
            userData,
            optionalFields,
            setOptionalFields,
            fetchOptionalFields, // Función disponible en el contexto
            localTourUUID,
            setLocalTourUUID,
            // fatherDomain,
            // setFatherDomain
        }}>
            {children}
        </AuthContext.Provider>
    );
};
